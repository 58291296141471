// Select

select.form-control {
	background-color: white;
	background: white;
	display: flex;
	border: 2px solid $gray !important;
	padding: 0.75rem auto;
	box-shadow: none !important;
	outline: 0px !important;
	height: 43px;

	// &:focus {
	// 	@include shadow();
	// }
	// @include shadow();
	// border: 0px !important;
	// font-size: 14px;
	&.input-gray{
		background-color: $gray !important;
	}
}