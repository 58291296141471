// Table

.table-component {
	background-color: transparent;
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	thead {
		border: 0px !important;
		th {
			border: 0px !important;
			color: $prod-blue1;
			text-transform: uppercase;
			font-size: 14px;
			font-family: $font-family-bold;
		}
	}
	tbody {
		border: 0px !important;
		tr {
			border: 0px !important;
		}
		td {
			border: 0px;
			font-size: 14px;
			button {
				margin-right: 5px;
			}
		}
	}
}

.table-component.table-color {
	cursor: pointer;
}

.no-table-data {
	font-size: 16px;
	border-radius: 2px;
	text-align: center;
	padding: 10px;
}

.custom-table {
	background-color: white;
	width: 100%;
	border-radius: 6px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	margin-bottom: 1rem;

	.header {
		display: flex;
		justify-content: space-between;
		padding: 0.75rem 1rem 0.75rem 0.5rem;
		margin-bottom: 1rem;

		.title {
			color: $font-color-secondary;
			font-size: 1.25rem;
		}

		.actions {
			display: flex;
			gap: 0.5rem;
		}
	}
	table {
		width: 100%;

		thead {
			th {
				color: $font-color-tertiary;
				font-size: 0.825rem;
				font-weight: 500;
				padding: 0.25rem;

				&.right {
					text-align: right;
				}
			}
			th:first-child {
				padding-left: 0.75rem;
			}
			th:last-child {
				padding-right: 0.75rem;
			}
		}
		tbody {
			tr {
				background-color: white;

				td {
					padding: 0.5rem 0.25rem;
					color: $font-color-secondary;
					border-top: 1px solid #e8e8e8;

					.badge {
						background-color: $gray;
						padding: 0.25rem 0.5rem;
						border-radius: 20px;
						font-weight: normal;

						&.badge--success {
							background-color: $green;
							color: white;
						}
						&.badge--warning {
							background-color: $danger;
							color: white;
						}
						&.badge--danger {
							background-color: $danger;
							color: white;
						}
					}

					.stars {
						display: flex;
						flex-direction: row;
						gap: 0.25rem;

						.fa-star, .fa-star-half {
							color: #ffbd00;
						}
					}

					&.row-actions {
						display: flex;
						gap: 0.5rem;

						&.right {
							justify-content: flex-end;
						}
					}

					.action-icon {
						cursor: pointer;
						width: 20px;
					}
				}

				td:first-child {
					padding-left: 0.75rem;
				}
				td:last-child {
					padding-right: 0.75rem;
				}
			}
			tr:nth-child(odd) {
				background-color: $gray;
			}
		}
	}
	.no-items {
		text-align: center;
		padding-bottom: 2rem;
	}
}