#chat {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 64px);

  .list {
    flex: 0 0 30%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: $background;
    border-right: 1px solid $gray;
    height: 100%;

    .list-item {
      position: relative;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $gray;
      cursor: pointer;

      &.selected {
        background-color: rgba(0,0,0,0.1);
      }
      .photo {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 3rem;
          height: 3rem;
          object-fit: cover;
          margin: 0.75rem;
          border-radius: 50%;
          border: 1px solid $font-color-tertiary;
        }
      }
      .info {
        width: 100%;
        display: flex;
        font-size: 14px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        overflow-x: hidden;
        padding-right: 0.5rem;

        .group {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .name {
            flex-wrap: nowrap;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            font-family: $font-family-semi-bold;
          }
          .time {
            align-self: flex-end;
            display: flex;
            font-size: 11px;
            padding-left: 0.5rem;
          }
        }
        .last-message {
          flex-wrap: nowrap;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
          width: 100%;
        }
      }
    }
    .circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      box-shadow: 0px 0px 1px 1px #0000001a;
      background-color: $blue;
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
    .pulse {
      animation: pulse-animation 2s infinite;
    }
    @keyframes pulse-animation {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
      }
      100% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      }
    }
  }
  .conversation {
    display: flex;
    flex-direction: column;
    background-color: $white;
    width: 100%;
    height: 100%;

    .not-selected {
      display: flex;
      align-self: center;
      justify-content: center;
      margin: auto;
      font-size: 12px;
    }
    .messages {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      height: 100px;
      overflow-y: auto;
      padding: 1rem 2rem 0 1rem;

      .message-item {
        background-color: #EEEEFF;
        padding: 6px 7px 8px 9px;
        margin-bottom: 0.75rem;
        max-width: 75%;
        min-width: 1px;
        border-radius: 7.5px;
        align-self: flex-start;
        position: relative;

        &.is-sender {
          align-self: flex-end;
          background-color: #5050FF;

          .text, .time {
            color: white;
          }
        }
        .text {
          color: $font-color-secondary;
          font-size: 14px;

          .hidden-time {
            opacity: 0;
          }
        }
        .time {
          position: absolute;
          right: 7px;
          bottom: 4px;
          z-index: 10;
          color: $font-color-secondary;
          font-size: 11px;
        }
      }
    }
    .input-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      border-top: 1px solid $gray;
      padding: 1rem;
      width: 100%;

      input {
        display: flex;
        width: 100%;
        height: 36px;
        padding: 0 1rem;
        background-color: $background;
        border-radius: 20px;
        border: none;
        outline: 0;
      }
      .send {
        border: none;
        background-color: white;
        margin: 0 0.5rem;

        img {
          width: 32px;
        }
      }
    }
  }
}
#create-chat {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: calc(100vh - 64px);

  .list {
    flex: 0 0 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $gray;
    height: 100%;

    .list-item {
      display: flex;
      border-bottom: 1px solid $gray;
      cursor: pointer;

      &.selected {
        background-color: rgba(0,0,0,0.1);
      }
      .photo {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 3rem;
          height: 3rem;
          margin: 0.75rem;
          border-radius: 50%;
          object-fit: cover;
          border: 1px solid $font-color-tertiary;
        }
      }
      .info {
        width: 100%;
        display: flex;
        font-size: 14px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        overflow-x: hidden;

        .group {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .name {
            flex-wrap: nowrap;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            font-family: $font-family-semi-bold;
          }
          .time {
            align-self: flex-end;
            display: flex;
            font-size: 11px;
            padding-left: 0.5rem;
          }
        }
        .last-message {
          flex-wrap: nowrap;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
          width: 100%;
        }
      }
    }
  }
}
.add-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  .addButton {
    position: relative;
    top: 90%;
    left: 90%;
    .btn-style{
      background-color: red;
      width: 60px;
      padding: 10px;
      border-radius: 50%;
      height: 60px;
      cursor: pointer;
    }
    img {
      width: 40px;
      display: block;
      margin: 0 auto;
    }
  }
}

#chat .list::-webkit-scrollbar,
#chat .messages::-webkit-scrollbar {
  width: 6px !important;
}
#chat .list::-webkit-scrollbar-thumb,
#chat .messages::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.2);
}