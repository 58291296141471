// Input
.search-filter {
	background-color: white;
	display: flex;
	border: 2px solid $gray !important;

	img {
		width: 22px;
		margin: 0.5rem;
	}

	input {
		width: 100%;
		padding: 0.75rem auto;
		font-family: $font-family;
		outline: 0px !important;
		border: none;
		font-size: 14px;
	}
}

.form--group {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}

label {
	color: $font-color-tertiary;
	margin-bottom: 0.25rem;
}

.form--control {
	flex: 1;

	.input--label {
		color: $font-color-tertiary;
		margin-bottom: 0.25rem;
	}
	.form--input {
		display: flex;
		border: 1px solid $gray !important;
		border-radius: 5px;
		margin-bottom: 1rem;
		min-height: 43px;

		&.filter {
			background-color: white;
			border: 2px solid $gray !important;
			border-radius: 0;
		}
		&.no-margin {
			margin-bottom: 0;
		}
		.input--left-img, .input--right-img {
			width: 22px;
			margin: 0.5rem;
		}
		.input--left-img {
			margin-right: 0;
		}
		input {
			width: 100%;
			padding: 0.5rem 0.75rem;
			font-family: $font-family;
			outline: 0px !important;
			border: none;
			font-size: 14px;
			color: $font-color-secondary;
		}
	}
}


.input-label {
	color: $font-color-secondary;
}