// Button

.btn-primary {
	border-radius: 3px;
	color: #fff !important;
	min-width: 120px;
	outline: 0px !important;
	border: 0px !important;
	&:focus {
		box-shadow: none;
	}	
	font-size: 15px;
	padding-top: 8px;
	padding-bottom: 8px;
	background-color: $prod-green !important;
}

.btn-blue {
	background-color: $prod-green !important;
}

.btn-block {
	width: 100%;
}

.btn-outline {
	border: 1px solid $gray !important;
	background-color: transparent !important;
	min-width: auto !important;
	border-radius: 3px !important;
}

.btn-red {
	background-color: $danger !important;
}

.btn-green {
	background-color: $green !important;
}

.btn-red {
	background-color: $prod-red !important;
}

.btn-small {
	padding: 5px !important;
	padding-left: 8px !important;
	padding-right: 8px !important;
	font-size: 12px !important;
	min-width: 30px !important;
}

.btn-align-bottom {
	@media (min-width: 768px) {
		margin-top: 32.5px;
	}	
	margin-bottom: 15px;
}

.form--submit-button {
	background-color: $blue;
	padding: 0.75rem 3rem;
	border: none;
	border-radius: 5px;

	span {
		color: white;
		font-size: 12px;
		text-transform: uppercase;
	}
}

button[class^="btn"] {
	background-color: $gray;
	border: none;
	padding: 0.5rem 0.5rem;
	border-radius: 5px;

	&.btn--primary {
		background-color: $blue;
	}
	&.btn--primary span {
		color: white;
	}

	span {
		color: black;
		font-size: 0.75rem;
		text-transform: uppercase;
		font-family: $font-family;
	}
}