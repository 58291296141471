#admin-technicians {
  .search-bar {
    margin-bottom: 40px;
  }
}

#admin-tecnicians-modal-user-details {
  overflow-y: auto;

  &>.content {
    display: flex;
    flex: 1;
    flex-direction: row;

    .left {
      width: 12rem;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-right: 1rem;

      &>div {
        display: flex;
        flex-direction: column;

        .photo, .no-image {
          width: 8rem;
          height: 8rem;
          overflow: hidden;
          display: flex !important;
          border-radius: 50%;
          border: 5px solid $gray;
          align-self: center;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .name {
          text-align: center;
          font-size: 1.25rem;
          padding: 0 0.5rem;
        }
      }
    }

    .right {
      padding: 0 !important;
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      .prop {
        padding: 0;
        margin-bottom: 1rem;

        .label {
          color: $font-color-tertiary;
        }
      }
      .pdf-button {
        background-color: $blue;
        padding: 0.25rem 0.5rem;
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;

        img {
          width: 20px;
          fill: white;
        }
        span {
          margin-left: 0.5rem;
          color: white;
        }
      }
      .divider {
        border-top: 1px solid $gray;
      }
      .section-label {
        font-size: 1.5rem;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    gap: 1rem;
  }
}