#login {
  background-image: url('../../assets/img/login-bg.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-wrapper {
    width: 20rem;
    padding: 1.5rem 2rem;
    background-color: white;
    border-radius: 6px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 1.5rem;

      img {
        width: 70%;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.5rem;

      button {
        margin-top: 1rem;
      }
    }
    .forgot {
      display: flex;
      justify-content: center;

      span {
        color: $blue;
        font-size: 12px;
      }
    }
    .submit {
      display: flex;
      justify-content: center;

      button {
        width: 70%;
      }
    }
  }
}