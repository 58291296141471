// Home

#home {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	img {
		width: 90%;
		margin-top: -50px;
		max-width: 400px;
	}
}