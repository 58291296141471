#admin-scheduled-appointments {
  margin: 2rem 0;
  display: flex;

  .calendar {
    /* Leyendas */
    .legend {
      display: flex;
      margin-bottom: 10px;
      margin-left: 50px; /* ajusta el margen izquierdo para las leyendas */
    }
    
    .legend-item {
      display: flex;
      align-items: center;
      margin-right: 2rem; /* ajusta la distancia entre los elementos */
    }
    
    .circle-orange {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 5px;
      background-color: #fd7e14;
    }

    .circle-green {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 5px;
      background-color: #28a745;
    }
    
    
    flex: 1;

    .fc {
      .fc-toolbar {
        padding-left: 2rem;
        margin-bottom: 0;

        .fc-toolbar-title {
          text-transform: capitalize;
        }
        .fc-prev-button,
        .fc-next-button {
          background-color: transparent;
          border: none;

          span {
            font-size: 1.75rem !important;
          }
        }
        .fc-prev-button:focus,
        .fc-next-button:focus {
          box-shadow: none;
        }
      }
      #fc-legend {
        display: flex;
        flex-direction: row;
        margin: 0.5rem 0;

        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 1rem;

          .fc-legend-1,
          .fc-legend-2 {
            width: 16px;
            height: 16px;
            border-radius: 10px;
            margin-right: 0.25rem;
          }
          .fc-legend-1 {
            background-color: $orange;
          }
          .fc-legend-2 {
            background-color: $prod-green;
          }
        }
      }
      .fc-view-harness {
        background-color: $white;

        .fc-scrollgrid-section-header {
          background-color: $blue;

          .fc-day {
            border: none;

            a {
              color: $white;
              font-size: 12px;
              font-weight: normal;
              text-transform: capitalize;
              padding: 0.25rem 0;
            }
            a:hover {
              text-decoration: none;
            }
          }
        }
        .fc-scrollgrid-section-body {
          .fc-day {

            .fc-daygrid-day-top {
              flex-direction: row;

              a {
                color: $font-color-secondary;
              }
            }
            .fc-event,
            .fc-event:focus,
            .fc-event:focus::after {
              background: 'transparent';
              background-color: transparent;
              border: none;
              box-shadow: none;
              cursor: pointer;
            }

            .fc-event-title {
              color: white;
            }
          }
          .fc-day:has(.scheduled-appointment),
          .fc-day:has(.annual-review),
          .fc-day:has(.mixed-event) {

            .fc-daygrid-day-top a {
              color: $white
            }
          }
          .fc-day:has(.scheduled-appointment) {
            background: $orange;
          }
          .fc-day:has(.annual-review) {
            background: $prod-green;
          }
          .fc-day:has(.mixed-event) {
            background: linear-gradient($prod-green 50%, $orange 50%);
          }
        }
      }
    }
  }
  .appointments {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 2rem;
    padding: 0 1rem;

    .card {
      background: $white;
      width: 100%;
      height: 100%;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1px solid $gray;

        .date {
          color: $blue;
          font-size: 16px;
          font-family: $font-family-semi-bold;
        }
      }
      ul {
        padding: 0;
        margin: 0;

        li {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          list-style: none;
          padding: 0.75rem 1rem;
          border-bottom: 1px solid $gray;

          .profile {
            display: flex;
            justify-content: flex-start;

            img {
              width: 4rem;
              height: 4rem;
              border-radius: 2rem;
            }
            .details {
              display: flex;
              justify-content: center;
              flex-direction: column;
              margin-left: 1rem;

              .name {
                font-family: $font-family-semi-bold;
              }
              .level {
                font-size: 14px;
                color: $blue;
                font-family: $font-family-semi-bold;
                line-height: 10px;
              }
              .stars {
                margin-top: 0.5rem;
                display: flex;
                flex-direction: row;
                gap: 0.25rem;

                .fa-star, .fa-star-half {
                  color: #ffbd00;
                }
              }
            }
          }
          .right-action {
            align-items: center;
            display: flex;
            justify-content: center;

            span {
              color: $blue;
              font-family: $font-family-semi-bold;
              cursor: pointer;
            }
          }
        }
      }
      &.review {
        li {
          flex-direction: column !important;

          .sup {
            display: flex;
            flex: 1;
            justify-content: space-between;
          }
          .sub {
            display: flex;
            flex: 1;
            justify-content: space-between;
            margin-top: 0.5rem;
            margin-left: 5rem;

            button {
              border: none;
              border-radius: 30px;
              background-color: $blue;
              color: $white;
              font-size: 12px;
              padding: 0.25rem 1rem;

              &.green {
                background-color: $prod-green;
              }
            }
          }
        }
      }
    }
  }
}

#admin-sched-appo-modal-appo-details {
  p {
    margin-bottom: 0.25rem;
  }

  .owner, .company, .technician {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.25rem;

    p {
      flex: 1;
      font-size: 14px;
    }
    p:last-child {
      text-align: right;
    }
  }

  .divider {
    border-top: 1px solid $gray;
  }

  .company-label, .technician-label {
    margin-top: 0.25rem;
    color: $blue;
    font-size: 14px;
    font-family: $font-family-semi-bold;
  }
  .location-label {
    color: $font-color-tertiary;
    margin-top: 0.5rem;
  }

  .maps-location {
    display: flex;
    align-items: center;
    padding: 0.25rem;
    border: 2px solid $blue;
    border-radius: 30px;
    overflow: hidden;
    cursor: pointer;
    background-color: $white;

    img {
      width: 28px;
      height: 28px;
    }
    span {
      margin-left: 0.25rem;
      margin-right: 1rem;
      font-size: 14px;
      color: $blue;
      font-family: $font-family-semi-bold;
    }
  }

  .stars {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;

    .fa-star, .fa-star-half {
      color: #ffbd00;
    }
  }

  table {
    width: 100%;
    border: 1px solid $gray;

    thead {
      background-color: $gray;

      th {
        border: 1px solid $gray;
        font-size: 14px;
        font-weight: 400;
        color: $font-color-tertiary;
        padding: 0.75rem;
        vertical-align: bottom;
      }
    }
    tbody {

      tr {

        td {
          border: 1px solid $gray;
          padding: 0.5rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .submit {
    display: flex;
    justify-content: center;
    margin: 1.5rem auto;

    button {
      border: none;
      background-color: $prod-green;
      color: $white;
      font-size: 14px;
      padding: 0.75rem 3rem;
      border-radius: 30px;
    }
  }
}

#admin-sched-appo-modal-app-time {
  overflow-y: auto;

  .actions {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }
}