#admin-customers {
  .search-bar {
    margin-bottom: 40px;
  }
}

#admin-customers-modal-user-details {
  width: 100%;
  overflow-y: auto;

  &>.content {
    // display: flex;
    // flex: 1;
    // flex-direction: row;

    .wrapped {
      padding: 0 !important;
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      .prop {
        padding: 0;
        margin-bottom: 1rem;

        .label {
          color: $font-color-tertiary;
        }
      }
      .divider {
        border-top: 1px solid $gray;
      }
      .section-label {
        font-size: 1.5rem;
      }

      table {
        width: 100%;
        border: 1px solid $gray;

        thead {
          background-color: $gray;

          th {
            border: 1px solid $gray;
            font-size: 14px;
            font-weight: 400;
            color: $font-color-tertiary;
            padding: 0.75rem;
          }
        }
        tbody {

          tr {

            td {
              border: 1px solid $gray;
              padding: 0.5rem;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  .maps-location {
    display: flex;
    align-items: center;
    padding: 0.25rem;
    border: 2px solid $blue;
    border-radius: 30px;
    overflow: hidden;
    cursor: pointer;
    background-color: $white;

    img {
      width: 28px;
      height: 28px;
    }
    span {
      margin-left: 0.25rem;
      margin-right: 1rem;
      font-size: 14px;
      color: $blue;
      font-family: $font-family-semi-bold;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    gap: 1rem;
  }
}