#recover {
  background-image: url('../../assets/img/login-bg.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-wrapper {
    position: relative;
    width: 30rem;
    padding: 2rem 2rem;
    background-color: white;
    border-radius: 26px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .go-back {
      position: absolute;
      top: 1rem;
      left: 0.75rem;
      border: none;
      background-color: transparent;

      img {
        width: 32px;
      }
    }

    .icon {
      display: flex;
      justify-content: center;
      margin-bottom: 1.5rem;

      img {
        width: 72px;
      }
    }
    .info {
      font-size: 14px;
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1.5rem;
      padding: 0 4rem;
    }
    .submit {
      display: flex;
      justify-content: center;

      button {
        width: 70%;
      }
    }
  }
}