// Select Search

.container-select-search {
	position: relative;
	.form-control {
		padding: 0px;
		height: 43px;
	}
	.css-yk16xz-control, .css-1pahdxg-control {
		background-color: transparent !important;
		width: 100%;
		height: 100%;
		border: 2px solid $gray !important;
		box-shadow: none !important;
		outline: 0px !important;
	}
	.css-2b097c-container {
		outline: 0px !important;
		border: 0px !important;
	}
	.css-1uccc91-singleValue {
		font-size: 14px;
	}
	.image-search-input {
		width: 25px;
		position: absolute;
		top: 40px;
		right: 10px;
	}
	&.search-right {
		.css-1hb7zxy-IndicatorsContainer, 
		.css-tlfecz-indicatorContainer, 
		.css-1gtu0rj-indicatorContainer,
		.css-1okebmr-indicatorSeparator {
			display: none;
		}
	}	
}