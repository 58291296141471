// Variables

$background: #f7f7f8;
$font-color-primary: #060606;
$font-color-secondary: #6a6a6a;
$font-color-tertiary: #9a9a9a;

$gray: #efefef;
$blue: #0057ff;
$black: #222d32;
$white: #fff;
$input: #f1f1f1;
$active: #0d1214;
$danger: #e53935;
$green: #43A047;
$orange: #FF6819;
$light: #F5F5F5;

$prod-green: #4bdb84;
$prod-green2: #00a75f;
$prod-green3: #005f40;
$prod-gray: #f2f2f2;
$prod-gray2: #eaeaea;
$prod-blue1: #3654e8;
$prod-blue2: #009bdb;
$prod-light: #5c5b60;
$prod-orange: #ff5500;
$prod-black: #000;
$prod-purple: #2a4055;
$prod-table-green: #fafbfd;
$prod-yellow: #e8c221;
$prod-red: #df0611;
$prod-gray: #424242;
$prod-lightred: #fff5f5;
$prod-blue: #2281e0;


$fonts-path: "../../assets/fonts/";
$width-menu: 280px;
$header-height: 55px;

$font-family: "Montserrat-Regular";
$font-family-medium: "Montserrat-Medium";
$font-family-bold: "Montserrat-Bold";
$font-family-semi-bold: "Montserrat-SemiBold";