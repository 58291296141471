#admin-poster-services {
    .search-bar {
      max-width: 90%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 0.5rem;
  
      .col {
        margin-bottom: 1rem;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .form--control,
      .form-control,
      .form-group {
        margin-right: 1rem;
      }
    }
  }
  
  #admin-cs-modal-cs-details {
    width: 100%;
    overflow-y: auto;
  
    &>.content {
      // display: flex;
      // flex: 1;
      // flex-direction: row;
  
      .wrapped {
        padding: 0 !important;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
  
        .prop {
          padding: 0;
          margin-bottom: 1rem;
  
          .label {
            color: $font-color-tertiary;
            margin-bottom: 0.25rem;
          }
          select {
            height: 43px;
          }
        }
        .divider {
          border-top: 1px solid $gray;
        }
        .section-label {
          font-size: 1.5rem;
        }
        table {
          width: 100%;
          border: 1px solid $gray;
  
          thead {
            background-color: $gray;
  
            th {
              border: 1px solid $gray;
              font-size: 12px;
              font-weight: 400;
              color: $font-color-tertiary;
              padding: 0.75rem;
              vertical-align: bottom;
            }
          }
          tbody {
  
            tr {
  
              td {
                border: 1px solid $gray;
                padding: 0.5rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
        }
      }
      .no-items {
        text-align: center;
        padding-bottom: 2rem;
      }
    }
  
    .actions {
      display: flex;
      justify-content: center;
      margin-top: 0.75rem;
      margin-bottom: 0.5rem;
      gap: 1rem;
    }
  }