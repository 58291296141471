#admin-parts {
  .search-bar {
    margin-bottom: 40px;
  }
}

#admin-parts-modal-create-part {
  overflow-y: auto;

  &>.content {

    .divider {
      border-top: 1px solid $gray;
      margin-bottom: 0.5rem;
    }

    .wrapped {
      padding: 0 !important;
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      .prop {
        padding: 0;

        .label {
          color: $font-color-tertiary;
        }
      }
      .section-label {
        font-size: 1.5rem;
      }

      table {
        width: 100%;

        thead {

          th {
            color: $font-color-tertiary;
            font-size: 0.825rem;
            font-weight: 500;
            padding: 0.25rem;
          }
        }
        tbody {

          tr {

            td {
              padding: 0.5rem;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              align-items: center;

              &.row-actions {
                display: flex;
                justify-content: flex-end;
                gap: 0.5rem;

                &.right {
                  justify-content: flex-end;
                }

                .action-icon {
                  cursor: pointer;
                  width: 20px;
                }
              }
            }
          }
          tr:nth-child(odd) {
            background-color: $gray;
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    gap: 1rem;
  }
}