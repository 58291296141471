#layout {
  min-height: 100%;

  #sidebar {
    display: flex;
    width: 16rem;
    flex-direction: column;
    position: fixed;
    top: 0px;
    bottom: 0px;
    height: 100vh;
    background-image: url('../../assets/img/menu-bg.jpg') ;
    background-repeat: repeat-y;
    // background-size: 100%;

    .img {
      height: 100%;
      z-index: 1;
    }
    .content {
      z-index: 2;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      // padding-bottom: 1rem;
      overflow-y: auto;

      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 4rem;
        border-bottom: 1px solid #d1d5db;

        img {
          height: 3rem;
          width: auto;
        }
      }
      nav {
        flex: 1 1 0%;
        flex-direction: column;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 5px;
          background-color: rgba(0, 0, 0, 0.25);
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.25);
        }
        .menu-items {
          padding-top: 1.25rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          margin-bottom: 0.25rem;
        }
        .menu-item {
          display: flex;
          align-items: center;
          padding: 0.5rem;
          margin: 0.5rem auto;
          font-size: 0.85rem;
          font-family: $font-family-bold;
          line-height: 1.5rem;
          font-weight: 500;
          border-radius: 0.375rem;
          color: white;

          img {
            width: 1.5rem;
            margin-right: 1rem;
          }
        }
        .menu-item:hover {
          background-color: rgba($color: #FFFFFF, $alpha: 0.2);
          text-decoration: none;
        }
        .menu-item-selected {
          background-color: rgba($color: #FFFFFF, $alpha: 0.2);
        }
      }
    }
    .circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      box-shadow: 0px 0px 1px 1px #0000001a;
      background-color: $blue;
      position: absolute;
      right: 10px;
    }
    .pulse {
      animation: pulse-animation 2s infinite;
    }
    @keyframes pulse-animation {
      0% {
        box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.4);
      }
      100% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
      }
    }
  }
  .wrapper {
    padding-left: 16rem;
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;

    #header {
      height: 4rem;
      position: relative;
      z-index: 10;
      flex-shrink: 0;
      display: flex;
      background-color: white;
      border-bottom: 1px solid #d1d5db; // gray-300

      &>.content {
        flex: 1 1 0%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-left: auto;
        margin-right: auto;

        .breadcumbs {
          flex: 1 1 0%;
          display: flex;
          align-items: center;
          text-transform: capitalize;
        }
        .actions {
          margin-left: 1rem;
          display: flex;
          align-items: center;

          button {
            background-color: transparent;
            border: none;

            .span {
              color: #374151; // gray-700
              line-height: 0.875rem;
              font-size: 1.25rem;
              font-weight: 500;
              display: block;
            }
          }
        }
      }
    }

    &>.content:not(:has(#chat)):not(:has(#admin-scheduled-appointments)) {
      flex: 1 1 0%;
      padding: 2rem;
    }
  }
}