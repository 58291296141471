// Reset

#recover, #reset {
	.container-form {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 70vh;
		flex-direction: column;
		img {
			width: 300px;
			margin-bottom: 20px;
			margin-top: 20px;
		}
		.icon-recover {
			margin-bottom: 20px;
			img {
				width: 180px;
			}
		}
		form {
			p {
				font-family: $font-family;
				text-align: center;
				font-size: 14px;				
				max-width: 400px;
				margin: 0 auto;
				margin-bottom: 25px;
			}
			background-color: $white;
			@include shadow2();
			border-radius: 5px;
			overflow: hidden;
			padding: 20px;
			max-width: 600px;
			width: 90%;
			input {
				max-width: 100%;
				margin: 0 auto;
				font-size: 14px;
			}
			button {
				background-color: $prod-orange !important;
				color: $white !important;
				margin: 0 auto;
				border-radius: 5px;
				font-family: $font-family-bold;
				font-size: 14px;
				margin-top: 10px;
				min-width: 150px;
			}
		}		
	}
}